import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from '../Icon';

const baseClasses =
  'first:border-0 border-t border-secondary-light cursor-pointer select-none outline-none';

const StudyItem = ({
  date,
  description,
  numInstances,
  modalities,
  trackedSeries,
  isActive,
  onClick,
}) => {
  return (
    <div
      className={classnames(
        isActive ? 'bg-secondary-dark' : 'hover:bg-secondary-main bg-black',
        baseClasses
      )}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex="0"
    >
      {
        window.innerWidth <= 768 ?
          <div className="flex flex-col flex-1 px-4 pb-2">
            <div className="flex flex-row items-center justify-between pt-2 pb-2">
              {/* <div className="text-base text-white">{date}</div> */}

              <div className="pr-5 text-xl text-blue-300">{modalities}</div>
              <div className="flex flex-row items-center text-base text-blue-300">
                <Icon name="group-layers" className="w-4 mx-2 text-blue-300" />
                {numInstances}
              </div>
            </div>
            <div className="flex flex-row py-1">
              {/* <div className="pr-5 text-xl text-blue-300">{modalities}</div> */}
              <div className="text-base text-blue-300 break-words truncate-2-lines">
                {description}
              </div>
            </div>
          </div> :
          <div className="flex flex-col flex-1 px-2">
            {/* <div className="flex flex-row items-center justify-between pt-2 pb-2">
          <div className="text-base text-white">{date}</div>
          <div className="flex flex-row items-center text-base text-blue-300">
            <Icon
              name="group-layers"
              className="mx-2 w-4 text-blue-300"
            />
            {numInstances}
          </div>
        </div> */}
            <div className="flex flex-row py-1 items-center  ">
              <div className="pr-2 text-lg text-blue-300">{modalities}</div>
              <div className="text-xs text-blue-300 ">{description}</div>
            </div>
          </div>}
      {!!trackedSeries && (
        <div className="flex-2 flex">
          <div
            className={classnames(
              'bg-secondary-main mt-2 flex flex-row py-1 pl-2 pr-4 text-base text-white ',
              isActive
                ? 'border-secondary-light flex-1 justify-center border-t'
                : 'mx-4 mb-4 rounded-sm'
            )}
          >
            <Icon
              name="tracked"
              className="text-primary-light mr-2 w-4"
            />
            {trackedSeries} Tracked Series
          </div>
        </div>
      )}
    </div>
  );
};

StudyItem.propTypes = {
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  modalities: PropTypes.string.isRequired,
  numInstances: PropTypes.number.isRequired,
  trackedSeries: PropTypes.number,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default StudyItem;
