// External
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from '@ohif/i18n';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import Compose from './routes/Mode/Compose';
import { ServicesManager, ExtensionManager, CommandsManager, HotkeysManager, EventLogger } from '@ohif/core';
import {
  DialogProvider,
  Modal,
  ModalProvider,
  SnackbarProvider,
  ThemeWrapper,
  ViewportDialogProvider,
  ViewportGridProvider,
  CineProvider,
  UserAuthenticationProvider,
} from '@ohif/ui';
// Viewer Project
// TODO: Should this influence study list?
import { AppConfigProvider } from '@state';
import createRoutes from './routes';
import appInit from './appInit.js';
import OpenIdConnectRoutes from './utils/OpenIdConnectRoutes';
import { debounce } from 'lodash';
// import { postAIDataEvents } from 'platform/core/src/services/EventLogger/EventLogger';

const APP_VERSION = '1.17.04.2024';//versioncode.dd.mm.yyyy
console.log(`APP_VERSION:${APP_VERSION}`);

let commandsManager: CommandsManager,
  extensionManager: ExtensionManager,
  servicesManager: ServicesManager,
  hotkeysManager: HotkeysManager;

function App({ config, defaultExtensions, defaultModes }) {
  const [init, setInit] = useState(null);
  const eventlogger = EventLogger.getInstance();
  let isTabActive = true; // Flag to track tab activity

  // Define the function to send session data
  const sendSessionData = () => {
    if (window["eventsData"].session_id === undefined) {
      return;
    }
    if (window["prevEventsLength"] === window["eventsData"].events.length) {
      return;
    }
    const sessionData = {
      session_id: window["eventsData"].session_id,
      radiologist_id: window["eventsData"].radiologist_id || 0,
      studyreporttask_id: window["eventsData"].studyreporttask_id || 0,
      event_type: "viewer",
      session_dump: { ...window["eventsData"] }
    };

    // console.log(sessionData);

    // If there are events, send the session data
    if (window["eventsData"].events.length > 0) {
      eventlogger.postAIDataEvents(sessionData);
      // window["eventsData"].events = [];
    }
  };
  const debouncedSendSessionData = debounce(sendSessionData, 10);
  // Set up the timer
  const timerInterval = setInterval(() => {
    sendSessionData();
  }, 2 * 60 * 1000);

  useEffect(() => {

    // Handle tab close, out of tab, or browser back button press
    window.addEventListener('beforeunload', () => {
      console.log("close");
      debouncedSendSessionData();
    });

    // Optionally, you can also handle the back button press
    window.addEventListener('popstate', () => {
      console.log("back button");
      debouncedSendSessionData();
    });

    // Handle tab visibility change (e.g., tab switch)
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        console.log("visibility change");
        debouncedSendSessionData();
      }
    });

    // Handle focus changes (e.g., switching tabs using keyboard)
    window.addEventListener('focus', () => {
      console.log("focus");
      debouncedSendSessionData();
    });

    // Handle blur events (e.g., switching tabs using keyboard)
    window.addEventListener('blur', () => {
      console.log("blur");
      // You can choose to send session data here if needed
      // sendSessionData();
    });


  }, []);

  useEffect(() => {
    const run = async () => {
      appInit(config, defaultExtensions, defaultModes).then(setInit).catch(console.error);
    };

    run();
    // Timer logic
    // const timerInterval = setInterval(() => {
    //   // This function will be called every 2 minutes
    //   const sessionData = {
    //     session_id: window["eventsData"].session_id,
    //     radiologist_id: window["eventsData"].radiologist_id || "testing",
    //     studyreporttask_id: window["eventsData"].studyreporttask_id || "testing",
    //     event_type:"viewer",
    //     session_dump: {...window["eventsData"]}
    //   };
    //   // console.log('Timer fired!');
    //   console.log(sessionData);
    //   // window["eventsData"].events = [];
    //   if(window["eventsData"].events.length > 0){
    //     eventlogger.postAIDataEvents(sessionData);
    //   }
    // }, 5 * 1000);

    // return () => clearInterval(timerInterval);
  }, []);

  if (!init) {
    return null;
  }

  // Set above for named export
  commandsManager = init.commandsManager;
  extensionManager = init.extensionManager;
  servicesManager = init.servicesManager;
  hotkeysManager = init.hotkeysManager;

  // Set appConfig
  const appConfigState = init.appConfig;
  const { routerBasename, modes, dataSources, oidc, showStudyList } = appConfigState;

  const {
    uiDialogService,
    uiModalService,
    uiNotificationService,
    uiViewportDialogService,
    viewportGridService,
    cineService,
    userAuthenticationService,
    customizationService,
  } = servicesManager.services;

  const providers = [
    [AppConfigProvider, { value: appConfigState }],
    [UserAuthenticationProvider, { service: userAuthenticationService }],
    [I18nextProvider, { i18n }],
    [ThemeWrapper],
    [ViewportGridProvider, { service: viewportGridService }],
    [ViewportDialogProvider, { service: uiViewportDialogService }],
    [CineProvider, { service: cineService }],
    [SnackbarProvider, { service: uiNotificationService }],
    [DialogProvider, { service: uiDialogService }],
    [ModalProvider, { service: uiModalService, modal: Modal }],
  ];
  const CombinedProviders = ({ children }) => Compose({ components: providers, children });

  let authRoutes = null;

  // Should there be a generic call to init on the extension manager?
  customizationService.init(extensionManager);

  // Use config to create routes
  const appRoutes = createRoutes({
    modes,
    dataSources,
    extensionManager,
    servicesManager,
    commandsManager,
    hotkeysManager,
    routerBasename,
    showStudyList,
  });

  if (oidc) {
    authRoutes = (
      <OpenIdConnectRoutes
        oidc={oidc}
        routerBasename={routerBasename}
        userAuthenticationService={userAuthenticationService}
      />
    );
  }

  return (
    <CombinedProviders>
      <BrowserRouter basename={routerBasename}>
        {authRoutes}
        {appRoutes}
      </BrowserRouter>
    </CombinedProviders>
  );
}

App.propTypes = {
  config: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      routerBasename: PropTypes.string.isRequired,
      oidc: PropTypes.array,
      whiteLabeling: PropTypes.object,
      extensions: PropTypes.array,
    }),
  ]).isRequired,
  /* Extensions that are "bundled" or "baked-in" to the application.
   * These would be provided at build time as part of they entry point. */
  defaultExtensions: PropTypes.array,
};

App.defaultProps = {
  config: {
    /**
     * Relative route from domain root that OHIF instance is installed at.
     * For example:
     *
     * Hosted at: https://ohif.org/where-i-host-the/viewer/
     * Value: `/where-i-host-the/viewer/`
     * */
    routerBaseName: '/',
    /**
     *
     */
    showLoadingIndicator: true,
    showStudyList: true,
    oidc: [],
    extensions: [],
  },
  defaultExtensions: [],
};

export default App;

export { commandsManager, extensionManager, servicesManager };
