import React, { ReactNode, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import NavBar from '../NavBar';
import Svg from '../Svg';
import Icon from '../Icon';
import IconButton from '../IconButton';
import Dropdown from '../Dropdown';
import html2canvas from 'html2canvas';
import Button, { ButtonEnums } from '../Button';

function Header({
  children,
  menuOptions,
  isReturnEnabled,
  onClickReturnButton,
  isSticky,
  WhiteLabeling,
  ...props
}): ReactNode {
  const { t } = useTranslation('Header');


  const [capturedImages, setCapturedImages] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isHQOn, setIsHQOn] = useState(false);

  const toggleHQ = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const hqParam = urlParams.get('hq');
    const newState = hqParam !== 'true'; // Toggle the state
    urlParams.set('hq', newState ? 'true' : 'false'); // Set the parameter as a string
    // window.history.replaceState(null, '', `?${urlParams.toString()}`); // Update the URL without reloading
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    window.location.reload();
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const hqParam = urlParams.get('hq');
    const hq = hqParam === 'true';
    setIsHQOn(hq);

    // const storedState = localStorage.getItem('hqState');
    // // console.log(storedState);
    // if (storedState !== null) {
    //   setIsHQOn(JSON.parse(storedState));
    // }
  }, []);

  // TODO: this should be passed in as a prop instead and the react-router-dom
  // dependency should be dropped
  const onClickReturn = () => {
    if (isReturnEnabled && onClickReturnButton) {
      onClickReturnButton();
    }
  };

  // capture images
  const captureImage = () => {
    const VIEWPORT_ID = 'default';
    const divForDownloadViewport = document.querySelector(
      `div[data-viewport-uid="${VIEWPORT_ID}"]`
    );
    if (divForDownloadViewport) {
      html2canvas(divForDownloadViewport).then(canvas => {
        const capturedImage = canvas.toDataURL('image/png'); // Adjust file type as needed
        // setCapturedImages(prevImages => [...prevImages, capturedImage]);
        window['capturedImages'] = [...(window['capturedImages'] || []), capturedImage];
        // window['capturedImages'].push(captureImage); // = (prevImages) => [...prevImages, capturedImage];
      });
    } else {
      console.error('divForDownloadViewport is not available or not yet rendered.');
    }
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    console.log("satish")
  };

  return (
    <NavBar
      className="justify-between border-b-4 border-black"
      isSticky={isSticky}
    >
      <div className="flex flex-grow justify-between"
        style={{
          flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
          alignItems: window.innerWidth >= 768 && 'center'
        }}>
        <div className="flex justify-start items-center">
          {/* // TODO: Should preserve filter/sort
              // Either injected service? Or context (like react router's `useLocation`?) */}
          {/* <div
            className={classNames(
              'mr-3 inline-flex items-center',
              isReturnEnabled && 'cursor-pointer'
            )}
            onClick={onClickReturn}
          >
            {isReturnEnabled && (
              <Icon
                name="chevron-left"
                className="text-primary-active w-8"
              />
            )}
            <div className="ml-4">
              {WhiteLabeling?.createLogoComponentFn?.(React, props) || <Svg name="logo-ohif" />}
            </div>
          </div> */}
          <div style={{ display: window.innerWidth <= 768 ? 'none' : 'block' }}>
            {WhiteLabeling?.createLogoComponentFn?.(React, props) || (
              <Svg name="logo-ohif" />
            )}
          </div>
          {window.innerWidth > 768 && <div className=""
            style={{
              color: window.innerWidth <= 768 ? "yellow" : "white",
              marginBottom: window.innerWidth <= 768 && "5px",
              fontWeight: window.innerWidth <= 768 && "normal",
              maxWidth: "100%", overflow: "hidden",
              textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: window.innerWidth <= 768 ? "12px" : "12px",
            }}>
            {window.self == window.top && window["azureData"]?.patient_info}</div>}
        </div>
        <div className="" >{children}</div>
        <div className='items-center' style={{ display: window.innerWidth <= 768 ? 'none' : 'flex' }}>
          <div className="flex items-center">
            {(window["azureData"]?.modality?.toLowerCase() === 'cr' || window["azureData"]?.modality?.toLowerCase() === 'dx') && (
              <button
                aria-pressed={isHQOn}
                className={`focus:outline-none text-xs font-semibold uppercase border ${isHQOn ? 'bg-white text-gray-600 border-gray-600' : 'bg-transparent border-gray-600 text-gray-600'
                  }`}
                onClick={toggleHQ}
                style={{ padding: '2px 6px', fontSize: '0.75rem', borderRadius: '3px' }}
              >
                HQ
              </button>
            )}

            {/* {!window["azureData"]?.modality?.toLowerCase().includes("pt") && <Button
              className="ml-2"
              onClick={captureImage}
              type={ButtonEnums.type.secondary}
              name={'Capture'}
            >
              {t('Capture')}
            </Button>} */}
            {/* <button className="flex items-center justify-center px-2 py-1 border border-transparent rounded-md shadow-sm text-sm font-medium
         text-primary-light bg-secondary-dark hover:bg-primary-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-main" onClick={captureImage}>Capture</button> */}
          </div>
          {/* <button className="flex items-center justify-center px-2 py-1 border border-transparent rounded-md shadow-sm text-sm font-medium
         text-primary-light bg-primary-dark hover:bg-primary-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-main">

          Download Studies
        </button> */}

          {WhiteLabeling?.createLogoComponentFn?.(React, props) || (
            <></>  // <Svg name="logo-ohif" />
          )}
        </div>
        {/* <div className="flex items-center">
          <span className="mr-3 text-lg text-common-light">
            {t('INVESTIGATIONAL USE ONLY')}
          </span>
          <Dropdown id="options" showDropdownIcon={false} list={menuOptions}>
            <IconButton
              id={'options-settings-icon'}
              variant="text"
              color="inherit"
              size="initial"
              className="text-primary-active"
            >
              <Icon name="settings" />
            </IconButton>
            <IconButton
              id={'options-chevron-down-icon'}
              variant="text"
              color="inherit"
              size="initial"
              className="text-primary-active"
            >
              <Icon name="chevron-down" />
            </IconButton>
          </Dropdown>
        </div> */}
      </div>
      {/* <div className="flex items-center">
        <button onClick={captureImage}>Capture</button>
        <button onClick={toggleDrawer}>Show Captured Images</button>
      </div>
      {isDrawerOpen && (
        <div className="drawer">
          <h2>Captured Images</h2>
          <ul>
            {capturedImages.map((image, index) => (
              <li key={index}>
                <img src={image} alt={`Captured Image ${index + 1}`} />
              </li>
            ))}
          </ul>
        </div>
      )} */}

    </NavBar>
  );
}

Header.propTypes = {
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    })
  ),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isReturnEnabled: PropTypes.bool,
  isSticky: PropTypes.bool,
  onClickReturnButton: PropTypes.func,
  WhiteLabeling: PropTypes.object,
};

Header.defaultProps = {
  isReturnEnabled: true,
  isSticky: false,
};

export default Header;
