import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SwiperCore, { A11y, Controller, Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import html2canvas from 'html2canvas';
import { PanelService, ServicesManager, Types } from '@ohif/core';

import LegacyButton from '../LegacyButton';
import Icon from '../Icon';
import IconButton from '../IconButton';
import Tooltip from '../Tooltip';

import 'swiper/css';
import 'swiper/css/navigation';
import './style.css';
import Button, { ButtonEnums } from '../Button';

const borderSize = 4;
const expandedWidth = 248;
const collapsedWidth = 25;

const baseStyle = {
  maxWidth: `${expandedWidth}px`,
  width: `${expandedWidth}px`,
};

const collapsedHideWidth = expandedWidth - collapsedWidth - borderSize;
const styleMap = {
  open: {
    left: { marginLeft: '0px' },
    right: { marginRight: '0px' },
  },
  closed: {
    left: { marginLeft: `-${collapsedHideWidth}px` },
    right: { marginRight: `-${collapsedHideWidth}px` },
  },
};

const baseClasses =
  'transition-all duration-300 ease-in-out h-100 bg-black border-black justify-start box-content flex flex-col';

const classesMap = {
  open: {
    left: `mr-1`,
    right: `ml-1`,
  },
  closed: {
    left: `mr-2 items-end`,
    right: `ml-2 items-start`,
  },
};

const openStateIconName = {
  left: 'push-left',
  right: 'push-right',
};

const position = {
  left: {
    right: 5,
  },
  right: {
    left: 5,
  },
};

const CapturedImagesSidePanel = ({
  servicesManager,
  side,
  className,
  activeTabIndex: activeTabIndexProp,
  tabs,
}) => {
  const panelService: PanelService = servicesManager?.services?.panelService;

  const { t } = useTranslation('CapturedImagesSidePanel');

  // Tracks whether this CapturedImagesSidePanel has been opened at least once since this CapturedImagesSidePanel was inserted into the DOM.
  // Thus going to the Study List page and back to the viewer resets this flag for a CapturedImagesSidePanel.
  const [hasBeenOpened, setHasBeenOpened] = useState(activeTabIndexProp !== null);
  const [panelOpen, setPanelOpen] = useState(activeTabIndexProp !== null);
  const [activeTabIndex, setActiveTabIndex] = useState(activeTabIndexProp ?? 0);
  const swiperRef = useRef() as any;
  const [swiper, setSwiper] = useState<any>();

  const [capturedImages, setCapturedImages] = useState(window['capturedImages'] || []);
  const prevRef = React.useRef();
  const nextRef = React.useRef();

  const openStatus = panelOpen ? 'open' : 'closed';
  const style = Object.assign({}, styleMap[openStatus][side], baseStyle);

  // const ActiveComponent = tabs[activeTabIndex].content;

  useEffect(() => {
    if (panelOpen && swiper) {
      swiper.slideTo(activeTabIndex, 500);
    }
  }, [panelOpen, swiper]);

  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  const updatePanelOpen = useCallback((panelOpen: boolean) => {
    setPanelOpen(panelOpen);
    if (panelOpen) {
      setHasBeenOpened(true);
    }
  }, []);

  const updateActiveTabIndex = useCallback(
    (activeTabIndex: number) => {
      setActiveTabIndex(activeTabIndex);
      updatePanelOpen(true);
    },
    [updatePanelOpen]
  );

  useEffect(() => {
    if (panelService) {
      const activatePanelSubscription = panelService.subscribe(
        panelService.EVENTS.ACTIVATE_PANEL,
        (activatePanelEvent: Types.ActivatePanelEvent) => {
          if (!hasBeenOpened || activatePanelEvent.forceActive) {
            const tabIndex = tabs.findIndex(tab => tab.id === activatePanelEvent.panelId);
            if (tabIndex !== -1) {
              updateActiveTabIndex(tabIndex);
            }
          }
        }
      );

      return () => {
        activatePanelSubscription.unsubscribe();
      };
    }
  }, [tabs, hasBeenOpened, panelService, updateActiveTabIndex]);

    // Function to capture the viewport

    // Function to download all captured images
    const downloadAllImages = () => {
      // Implement logic to download all captured images
      // For example, you can create a zip file containing all images
    };


  const getCloseStateComponent = () => {
    const _childComponents = Array.isArray(tabs) ? tabs : [tabs];
    return (
      <>
        <div
          className={classnames(
            'bg-secondary-dark flex h-[28px] w-full cursor-pointer items-center rounded-md',
            side === 'left' ? 'justify-end pr-2' : 'justify-start pl-2'
          )}
          onClick={() => {
            updatePanelOpen(prev => !prev);
          }}
          data-cy={`side-panel-header-${side}`}
        >
          <Icon
            name={openStateIconName[side]}
            // name={'navigation-panel-right-reveal'}
            className={classnames('text-primary-active', side === 'left' && 'rotate-180 transform')}
          />
        </div>
        <div className={classnames('mt-3 flex flex-col space-y-3')}>
        {/* <Tooltip
              position={side === 'left' ? 'right' : 'left'}
              // key={index}
              content={`${"Captured Images"}`}
              className={classnames(
                'flex items-center',
                side === 'left' ? 'justify-end ' : 'justify-start '
              )}
            >
              <IconButton
                id={`${"childComponent.name"}-btn`}
                variant="text"
                color="inherit"
                size="initial"
                className="text-primary-active"
                onClick={() => {
                  updateActiveTabIndex(index);
                }}
              >
                <Icon
                    name={openStateIconName[side]}
                  className="text-primary-active"
                  style={{
                    width: '22px',
                    height: '22px',
                  }}
                />
              </IconButton>
            </Tooltip> */}
          {/* {_childComponents.map((childComponent, index) => (
            <Tooltip
              position={side === 'left' ? 'right' : 'left'}
              key={index}
              content={`${childComponent.label}`}
              className={classnames(
                'flex items-center',
                side === 'left' ? 'justify-end ' : 'justify-start '
              )}
            >
              <IconButton
                id={`${childComponent.name}-btn`}
                variant="text"
                color="inherit"
                size="initial"
                className="text-primary-active"
                onClick={() => {
                  updateActiveTabIndex(index);
                }}
              >
                <Icon
                  name={childComponent.iconName}
                  className="text-primary-active"
                  style={{
                    width: '22px',
                    height: '22px',
                  }}
                />
              </IconButton>
            </Tooltip>
          ))} */}
        </div>
      </>
    );
  };

  function _getMoreThanOneTabLayout(
    swiperRef: any,
    setSwiper: React.Dispatch<any>,
    prevRef: React.MutableRefObject<undefined>,
    nextRef: React.MutableRefObject<undefined>,
    tabs: any,
    activeTabIndex: any,
    updateActiveTabIndex
  ) {

  // const [capturedImages, setCapturedImages] = useState(window['capturedImages'] || []);
    const capturedImages = window['capturedImages'] || [];
    // console.log(capturedImages);
    const handleDelete = (index) => {
      // Remove the image at the specified index
      const updatedImages = [...capturedImages];
      updatedImages.splice(index, 1);
      window['capturedImages'] = updatedImages;
      setCapturedImages(updatedImages);
    };

    const handleCopy = (image) => {
    const textarea = document.createElement('textarea');
    textarea.value = image;
    document.body.appendChild(textarea);
    // Select the textarea content
    textarea.select();
    // Execute the copy command
    document.execCommand('copy');
    document.body.removeChild(textarea);
    console.log('Image copied to clipboard!');
    };

    function handleDownload(base64Data) {
      const link = document.createElement('a');
      link.href = base64Data;
      const timestamp = new Date().getTime();
      link.download = `image_${timestamp}.png`;
      // link.download = "image";
      link.click();
  }

  function refreshData(){
    const updatedImages = [...capturedImages];
    // window['capturedImages'] = updatedImages;
    setCapturedImages(updatedImages);
  }
  function downloadAllImages() {
    const capturedImages = window['capturedImages'] || [];

    capturedImages.forEach((image, index) => {
      // Create an anchor element
      const link = document.createElement('a');
      // Set the href attribute to the data URL of the image
      link.href = image;
      // Set the download attribute to specify the default file name
      link.download = `captured_image_${index + 1}_${Date.now()}.png`;
      // Trigger a click on the anchor element to start the download
      link.click();
    });
  }



    return (
      <div
        className="flex-static collapse-sidebar relative"
        style={{
          backgroundColor: '#06081f',
        }}
      >
        <div className="w-full">
          <Swiper
            onInit={(core: SwiperCore) => {
              swiperRef.current = core.el;
            }}
            simulateTouch={false}
            modules={[Navigation, Pagination, Scrollbar, A11y, Controller]}
            slidesPerView={3}
            spaceBetween={5}
            onSwiper={swiper => setSwiper(swiper)}
            navigation={{
              prevEl: prevRef?.current,
              nextEl: nextRef?.current,
            }}
          >
            <div className='bg-secondary-dark '>
            <h2 className="font-sans text-center justify-center items-center outline-none transition duration-300 ease-in-out text-primary-active mb-5 p-1">Captured Images</h2>

            <div
              style={{ // Set the maximum height for scrolling
                overflowY: 'auto', // Enable vertical scrolling
              }}
            >
              <div className='flex justify-between m-1'>
                { capturedImages.length > 0 &&
                  <Button
                    className="flex m-1 justify-end"
                    // disabled={hasError}
                    onClick={downloadAllImages}
                    type={ButtonEnums.type.secondary}
                    name={'downloadAll'}
                  >
                    {t('Download All')}
                  </Button>
                  }
                    <Button
                    className="flex m-1 justify-end"
                    // disabled={hasError}
                    onClick={refreshData}
                    type={ButtonEnums.type.secondary}
                    name={'refresh'}
                  >
                    {t('Refresh')}
                  </Button>
              </div>

            <ul style={{ listStyle: 'none', padding: 0 }}>
              {capturedImages.map((image, index) => (
                <li key={index} style={{ marginBottom: '10px' }}>
                  <div style={{ position: 'relative' }}>
                    <img
                      src={image}
                      alt={`Captured Image ${index + 1}`}
                      onError={() => console.error(`Failed to load image at index ${index}: ${image}`)}
                      style={{ width: '100px', height: '100px', marginRight: '10px' }}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        marginRight: '3px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'end',
                      }}
                    >

                      <button className="text-base text-white" onClick={() => handleDownload(image)}>Download</button>
                      <button className="text-base text-white" onClick={() => handleDelete(index)}>Delete</button>
                      {/* <button className="text-primary-active" onClick={() => handleCopy(image)}>Copy</button> */}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
            {/* <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {capturedImages.map((image, index) => (
                <li key={index}>
                  <img
                    src={image}
                    alt={`Captured Image ${index + 1}`}
                    onError={() => console.error(`Failed to load image at index ${index}: ${image}`)}
                  />
                </li>
              ))}
            </div> */}

          </div>
          </Swiper>
        </div>
      </div>
    );
  }
  return (
    <>
       { window.innerWidth <= 768 ?
      <div
      className='flex flex-row overflow-auto'
      style={{height:'100px'}}
      // className={classnames(
      //   className,
      //   baseClasses,
      //   classesMap[openStatus][side]
      // )}
      // style={style}
    >
      {panelOpen ? (
        <React.Fragment>
          {/** Panel Header with Arrow and Close Actions */}
          <div
            className='flex flex-row bg-primary-dark h-9 cursor-pointer overflow-auto'
            // className={classnames(
            //   'flex flex-static px-[10px] bg-primary-dark h-9 cursor-pointer',
            //   tabs.length === 1 && 'mb-1'
            // )}
            onClick={() => {
              updatePanelOpen(prev => !prev);
              // slideToActivePanel();
            }}
            data-cy={`side-panel-header-${side}`}
          >
            {/* <Button
              variant="text"
              color="inherit"
              border="none"
              rounded="none"
              className="flex flex-row flex-static items-center px-3 relative w-full"
              name={tabs.length === 1 ? `${tabs[activeTabIndex].name}` : ''}
            >
              <Icon
                name={openStateIconName[side]}
                className={classnames(
                  'text-primary-active absolute',
                  side === 'left' && 'order-last'
                )}
                style={{ ...position[side] }}
              />
              <span className="text-primary-active">
                {tabs.length === 1 && (t(tabs[activeTabIndex].label) as string)}
              </span>
            </Button> */}
          </div>
          {/* {tabs.length > 1 &&
            _getMoreThanOneTabLayout(
              swiperRef,
              setSwiper,
              prevRef,
              nextRef,
              tabs,
              activeTabIndex,
              updateActiveTabIndex
            )} */}
          {/** carousel navigation with the arrows */}
          {/** only show carousel nav if tabs are more than 3 tabs */}
          {tabs.length > 3 && (
            <div className="text-primary-active w-full flex justify-end gap-2 bg-primary-dark py-1 px-2">
              <button ref={prevRef} className="swiper-button-prev-custom">
                <Icon
                  name={'icon-prev'}
                  className={classnames('text-primary-active')}
                />
              </button>
              <button ref={nextRef} className="swiper-button-next-custom">
                <Icon
                  name={'icon-next'}
                  className={classnames('text-primary-active')}
                />
              </button>
            </div>
          )}
          {/* <ActiveComponent /> */}
        </React.Fragment>
      ) : (
        <React.Fragment>{getCloseStateComponent()}</React.Fragment>
      )}
    </div> :
      <div
      className={classnames(
        className,
        baseClasses,
        classesMap[openStatus][side]
      )}
      style={style}
    >
      {panelOpen ? (
        <React.Fragment>
          {/** Panel Header with Arrow and Close Actions */}
          <div
            className={classnames(
              'flex-static bg-primary-dark flex h-9 cursor-pointer px-[10px]',
              tabs.length === 1 && 'mb-1'
            )}
            onClick={() => {
              updatePanelOpen(prev => !prev);
              // slideToActivePanel();
            }}
            data-cy={`side-panel-header-${side}`}
          >
            {/* TODO This should be redesigned to not be a button. */}
            <LegacyButton
              variant="text"
              color="inherit"
              border="none"
              rounded="none"
              className="flex-static relative flex w-full flex-row items-center px-3"
              name={tabs.length === 1 ? `${tabs[activeTabIndex].name}` : ''}
            >
              <Icon
                name={openStateIconName[side]}
                className={classnames(
                  'text-primary-active absolute',
                  side === 'left' && 'order-last'
                )}
                style={{ ...position[side] }}
              />
              {/* Todo: ass secondary label here */}
              <span className="text-primary-active">
                {tabs.length === 1 && (t(tabs[activeTabIndex].label) as string)}
              </span>
            </LegacyButton>
          </div>
          {tabs.length === 0 &&
            _getMoreThanOneTabLayout(
              swiperRef,
              setSwiper,
              prevRef,
              nextRef,
              tabs,
              activeTabIndex,
              updateActiveTabIndex
            )}
          {/** carousel navigation with the arrows */}
          {/** only show carousel nav if tabs are more than 3 tabs */}
          {tabs.length > 3 && (
            <div className="text-primary-active bg-primary-dark flex w-full justify-end gap-2 py-1 px-2">
              <button
                ref={prevRef}
                className="swiper-button-prev-custom"
              >
                <Icon
                  name={'icon-prev'}
                  className={classnames('text-primary-active')}
                />
              </button>
              <button
                ref={nextRef}
                className="swiper-button-next-custom"
              >
                <Icon
                  name={'icon-next'}
                  className={classnames('text-primary-active')}
                />
              </button>
            </div>
          )}
          {/* <ActiveComponent /> */}
        </React.Fragment>
      ) : (
        <React.Fragment>{getCloseStateComponent()}</React.Fragment>
      )}
    </div>
    }
    </>

  );
};

CapturedImagesSidePanel.defaultProps = {
  defaultComponentOpen: null,
};

CapturedImagesSidePanel.propTypes = {
  servicesManager: PropTypes.instanceOf(ServicesManager),
  side: PropTypes.oneOf(['left', 'right']).isRequired,
  className: PropTypes.string,
  activeTabIndex: PropTypes.number,
  // tabs: PropTypes.oneOfType([
  //   PropTypes.arrayOf(
  //     PropTypes.shape({
  //       iconName: PropTypes.string.isRequired,
  //       iconLabel: PropTypes.string.isRequired,
  //       name: PropTypes.string.isRequired,
  //       label: PropTypes.string.isRequired,
  //       content: PropTypes.func, // TODO: Should be node, but it keeps complaining?
  //     })
  //   ),
  // ]),
};


export default CapturedImagesSidePanel;
