import React from 'react';
import PropTypes from 'prop-types';

import Thumbnail from '../Thumbnail';
import ThumbnailNoImage from '../ThumbnailNoImage';
import ThumbnailTracked from '../ThumbnailTracked';
import * as Types from '../../types';
import { EventLogger } from '@ohif/core';

const ThumbnailList = ({
  thumbnails,
  onThumbnailClick,
  onThumbnailDoubleClick,
  onClickUntrack,
  activeDisplaySetInstanceUIDs = [],
}) => {

  // events instance
  const eventLogger = EventLogger.getInstance();

  // Sort thumbnails by modality
  thumbnails.sort((a, b) => {
    const modalityA = a.modality || 'ZZZZ'; // 'ZZZZ' will place items without modality at the end
    const modalityB = b.modality || 'ZZZZ';
    return modalityA.localeCompare(modalityB);
  });
  thumbnails.sort((a, b) => {
    if (a.componentType === 'thumbnailNoImage') return 1;
    if (b.componentType === 'thumbnailNoImage') return -1;
    return 0;
  });
  return (
    <div
      id="ohif-thumbnail-list"
      className={`${window.innerWidth <= 768 ? 'flex flex-row bg-black overflow-auto ohif-scrollbar p-0' : 'py-1 bg-black overflow-auto ohif-scrollbar study-min-height'}`}
    // style={{flexDirection : window.innerWidth <= 768 ? 'column': 'row'}}
    >
      {

        thumbnails.map(
          ({
            displaySetInstanceUID,
            description,
            dragData,
            seriesNumber,
            numInstances,
            modality,
            componentType,
            seriesDate,
            countIcon,
            viewportIdentificator,
            isTracked,
            canReject,
            onReject,
            imageSrc,
            messages,
            imageAltText,
            isHydratedForDerivedDisplaySet,
          }) => {
            const isActive = activeDisplaySetInstanceUIDs.includes(displaySetInstanceUID);
            description = description || 'Desc Not Available';
            window["isStillLoading"] = true;
            switch (componentType) {
              case 'thumbnail':
                return (
                  <Thumbnail
                    key={displaySetInstanceUID}
                    displaySetInstanceUID={displaySetInstanceUID}
                    dragData={dragData}
                    description={description}
                    seriesNumber={seriesNumber}
                    numInstances={numInstances}
                    countIcon={countIcon}
                    imageSrc={imageSrc}
                    imageAltText={imageAltText}
                    messages={messages}
                    viewportIdentificator={viewportIdentificator}
                    isActive={isActive}
                    onClick={() => {
                      const logData = {
                        interactionType: 'selected_series',

                      };
                      eventLogger.logInteraction(logData);

                      onThumbnailClick(displaySetInstanceUID)}}
                    onDoubleClick={() => {
                      const logData = {
                        interactionType: 'selected_series',

                      };
                      eventLogger.logInteraction(logData);

                      onThumbnailDoubleClick(displaySetInstanceUID)}}
                  />
                );
              case 'thumbnailTracked':
                return (
                  <ThumbnailTracked
                    key={displaySetInstanceUID}
                    displaySetInstanceUID={displaySetInstanceUID}
                    dragData={dragData}
                    description={description}
                    seriesNumber={seriesNumber}
                    numInstances={numInstances}
                    countIcon={countIcon}
                    imageSrc={imageSrc}
                    imageAltText={imageAltText}
                    messages={messages}
                    viewportIdentificator={viewportIdentificator}
                    isTracked={isTracked}
                    isActive={isActive}
                    onClick={() => {
                      const logData = {
                        interactionType: 'selected_series',

                      };
                      eventLogger.logInteraction(logData);


                      onThumbnailClick(displaySetInstanceUID)}}
                    onDoubleClick={() => {
                      const logData = {
                        interactionType: 'selected_series',

                      };
                      eventLogger.logInteraction(logData);

                      onThumbnailDoubleClick(displaySetInstanceUID)}}
                    onClickUntrack={() => onClickUntrack(displaySetInstanceUID)}
                  />
                );
              case 'thumbnailNoImage':
                return (
                  <ThumbnailNoImage
                    isActive={isActive}
                    key={displaySetInstanceUID}
                    displaySetInstanceUID={displaySetInstanceUID}
                    dragData={dragData}
                    modality={modality}
                    modalityTooltip={_getModalityTooltip(modality)}
                    messages={messages}
                    seriesDate={seriesDate}
                    description={description}
                    canReject={canReject}
                    onReject={onReject}
                    onClick={() => {
                      const logData = {
                        interactionType: 'selected_series',

                      };
                      eventLogger.logInteraction(logData);

                      onThumbnailClick(displaySetInstanceUID)}}
                    onDoubleClick={() => onThumbnailDoubleClick(displaySetInstanceUID)}
                    viewportIdentificator={viewportIdentificator}
                    isHydratedForDerivedDisplaySet={isHydratedForDerivedDisplaySet}
                  />
                );
              default:
                return <></>;
            }
          }
        )}
    </div>
  );
};

ThumbnailList.propTypes = {
  thumbnails: PropTypes.arrayOf(
    PropTypes.shape({
      displaySetInstanceUID: PropTypes.string.isRequired,
      imageSrc: PropTypes.string,
      imageAltText: PropTypes.string,
      seriesDate: PropTypes.string,
      seriesNumber: Types.StringNumber,
      numInstances: PropTypes.number,
      description: PropTypes.string,
      componentType: Types.ThumbnailType.isRequired,
      viewportIdentificator: Types.StringArray,
      isTracked: PropTypes.bool,
      /**
       * Data the thumbnail should expose to a receiving drop target. Use a matching
       * `dragData.type` to identify which targets can receive this draggable item.
       * If this is not set, drag-n-drop will be disabled for this thumbnail.
       *
       * Ref: https://react-dnd.github.io/react-dnd/docs/api/use-drag#specification-object-members
       */
      dragData: PropTypes.shape({
        /** Must match the "type" a dropTarget expects */
        type: PropTypes.string.isRequired,
      }),
    })
  ),
  activeDisplaySetInstanceUIDs: PropTypes.arrayOf(PropTypes.string),
  onThumbnailClick: PropTypes.func.isRequired,
  onThumbnailDoubleClick: PropTypes.func.isRequired,
  onClickUntrack: PropTypes.func.isRequired,
};

// TODO: Support "Viewport Identificator"?
function _getModalityTooltip(modality) {
  if (_modalityTooltips.hasOwnProperty(modality)) {
    return _modalityTooltips[modality];
  }

  return 'Unknown';
}

const _modalityTooltips = {
  SR: 'Structured Report',
  SEG: 'Segmentation',
  RTSTRUCT: 'RT Structure Set',
};

export default ThumbnailList;
