import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDrag } from 'react-dnd';
import Icon from '../Icon';
import { StringNumber } from '../../types';
import DisplaySetMessageListTooltip from '../DisplaySetMessageListTooltip';
import ProgressLoadingBar from '../ProgressLoadingBar';

/**
 * Display a thumbnail for a display set.
 */
const Thumbnail = ({
  displaySetInstanceUID,
  className,
  imageSrc,
  imageAltText,
  description,
  seriesNumber,
  numInstances,
  countIcon,
  messages,
  dragData,
  isActive,
  onClick,
  onDoubleClick,
}): React.ReactNode => {
  // TODO: We should wrap our thumbnail to create a "DraggableThumbnail", as
  // this will still allow for "drag", even if there is no drop target for the
  // specified item.
  const [collectedProps, drag, dragPreview] = useDrag({
    type: 'displayset',
    item: { ...dragData },
    canDrag: function (monitor) {
      return Object.keys(dragData).length !== 0;
    },
  });

  // window["isStillLoading"] = true;
  // console.log(isActive)
  if (isActive && imageSrc !== undefined && imageSrc.includes("data:image")) {
    window["isStillLoading"] = false;
    console.log("satish  - false")
  }
  return (
    <div
      className={classnames(
        className,
        'flex flex-1 mb-1  cursor-pointer outline-none select-none group',
        window.innerWidth <= 768 ? 'flex-row' : 'px-1',
      )}
      // style={{padding: window.innerWidth <= 768 ? '-2px !important' : ''}}
      id={`thumbnail-${displaySetInstanceUID}`}
      data-cy={`study-browser-thumbnail`}
      onClick={onDoubleClick}
      onDoubleClick={onDoubleClick}
      role="button"
      tabIndex="0"
    >
      <div ref={drag} className={classnames(
        'flex',
        window.innerWidth <= 768 ? 'flex-row pl-1 pr-1' : 'flex-col'
      )}
        style={{ width: window.innerWidth <= 768 && 'max-content' }}
      >
        <div
          className={classnames(
            'flex flex-1 items-center justify-center rounded-md bg-black text-base text-white overflow-hidden',
            window.innerWidth <= 768 ? 'h-12' : 'w-24 h-32',
            isActive
              ? 'border-primary-light border-2'
              : 'border-secondary-light border hover:border-blue-300'
          )}
          style={{
            margin: isActive ? '0' : '1px',
          }}
        >
          {imageSrc ? (
            <img
              src={imageSrc}
              alt={imageAltText}
              className={`${window.innerWidth <= 768 ? 'object-cover w-12 h-12' : 'object-contain w-24 h-20'}`}
              crossOrigin="anonymous"
            />
          ) : (
            <div>
              <div
                className={`'absolute top-0 left-0 z-50 flex flex-col items-center justify-center space-y-5' ${window.innerWidth <= 768 ? 'w-8 h-8 m-2' : 'h-16'}`}
              // className={"'absolute top-0 left-0 z-50 flex flex-col items-center justify-center space-y-5'"}
              >
                <div className={`w-5 h-5 border-t-4 border-primary-light border-solid rounded-full animate-spin`}></div>
                {/* <img
                src={"../assets/icons/logo.gif"}
                alt={imageAltText}
                className={`${window.innerWidth <= 768 ? 'object-cover w-12 h-12' : 'object-none min-h-32'}`}
                crossOrigin="anonymous"
              /> */}
                {window.innerWidth <= 768 ? "" : "Loading..."}
              </div>
            </div>
          )}

        </div>
        {window.innerWidth <= 768 ?
          <div className='flex flex-col mx-2'>
            <div className="flex items-center text-base text-blue-300">
              <div className="mr-3">
                <span className="font-bold text-primary-main">{'S: '}</span>
                {seriesNumber}
              </div>
              <div className="flex flex-row items-center flex-1">
                <Icon name={countIcon || 'group-layers'} className="w-3 mr-2" />
                {` ${numInstances}`}
              </div>
            </div>
            <div className="text-base text-white break-all">{description}</div>
          </div> :
          <>
            <div className="flex flex-row items-center flex-1 text-base text-blue-300" style={{ fontSize: '0.65rem', marginTop: '-10px' }}>
              <div className="mr-4">
                <span className="font-bold text-primary-main">{'S: '}</span>
                {seriesNumber}
              </div>
              <div className="flex flex-row items-center flex-1">
                <Icon name={countIcon || 'group-layers'} className="w-3 mr-2" />
                {` ${numInstances}`}
              </div>
            </div>
            <div className="text-base text-white break-all" style={{ fontSize: '0.65rem', marginTop: '-15px' }}>{description}</div>
          </>
        }
      </div>
    </div>
  );
};

Thumbnail.propTypes = {
  displaySetInstanceUID: PropTypes.string.isRequired,
  className: PropTypes.string,
  imageSrc: PropTypes.string,
  /**
   * Data the thumbnail should expose to a receiving drop target. Use a matching
   * `dragData.type` to identify which targets can receive this draggable item.
   * If this is not set, drag-n-drop will be disabled for this thumbnail.
   *
   * Ref: https://react-dnd.github.io/react-dnd/docs/api/use-drag#specification-object-members
   */
  dragData: PropTypes.shape({
    /** Must match the "type" a dropTarget expects */
    type: PropTypes.string.isRequired,
  }),
  imageAltText: PropTypes.string,
  description: PropTypes.string.isRequired,
  seriesNumber: StringNumber.isRequired,
  numInstances: PropTypes.number.isRequired,
  messages: PropTypes.object,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
};

Thumbnail.defaultProps = {
  dragData: {},
};

export default Thumbnail;
