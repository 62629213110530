// loadStudyFromAzure.js

// Assuming you have pako installed for gzip decompression.
import { DicomMetadataStore } from '@ohif/core';
import pako from 'pako';
import dcmjs from 'dcmjs';

async function fetchGzippedJSON(url) {
  const response = await fetch(url);

  if (!response.ok) {
    throw new Error(
      `Failed to fetch data from ${url}. Status: ${response.status}`
    );
  }

  const compressedData = await response.arrayBuffer();
  const decompressedData = pako.inflate(new Uint8Array(compressedData), {
    to: 'string',
  });
  return JSON.parse(decompressedData);
}


async function getSeriesUids(seriesJsonUrl) {
  const response = await fetch(seriesJsonUrl);

  if (!response.ok) {
    throw new Error(
      `Failed to fetch data from ${seriesJsonUrl}. Status: ${response.status}`
    );
  }

  const data = await response.json();
  const seriesUids = data.map(series => series['0020000E'].Value[0]);

  // Assuming the first seriesUID can be used to determine the studyInstanceUID:
  const studyInstanceUID = data[0]['0020000D'].Value[0];

  return { studyInstanceUID, seriesUids };
}

async function getMetadataForSeriesUid(url) {
  const response = await fetch(url);

  if (!response.ok) {
    throw new Error(
      `Failed to fetch data from ${url}. Status: ${response.status}`
    );
  }

  const data = await response.json();
  return data;
}

async function _loadV3Gzip(azData, loadHq) {
  console.log(azData.account)
  let azureUrl = `https://${azData.account}.blob.core.windows.net/${azData.container}/${azData.object}/meta/dicom_metadata.json.gz`;
  let hq = "";
  if (loadHq) {
    hq = "/hq"
  }
  const data = await fetchGzippedJSON(azureUrl);
  const studyInstanceUID = data[0]['0020000D'].Value[0];
  data.forEach(ins => {
    DicomMetadataStore.addInstance(ins);
  });
  DicomMetadataStore.getStudy(studyInstanceUID).series.forEach(serie => {
    serie.instances.forEach(ins => {
      // ins.url = `wadouri:https://telefiradiostorage.blob.core.windows.net/dicom-data/${ins.StudyInstanceUID}${hq}/${ins.SeriesInstanceUID}/${ins.SOPInstanceUID}.dcm`;
      ins.url = `wadouri:https://telefiradiostorage.blob.core.windows.net/${azData.container}/${azData.object}/${ins.SeriesInstanceUID}/${ins.SOPInstanceUID}.dcm`;

    })
  })
  setCurrentStudy(DicomMetadataStore.getStudy(studyInstanceUID));
  window["isStillLoading"] = false; // to show loader with icon, add these line wherever it is required for viewport
}

async function _loadV3(azData) {
  let azureUrl = `https://${azData.account}.blob.core.windows.net/${azData.container}/${azData.object}/meta/dicom_metadata.json`;
  const response = await fetch(azureUrl);
  const data = await response.json();
  const studyInstanceUID = data[0]["StudyUid"];
  data.forEach(ins => {
    const insMetadata = JSON.parse(ins.MetaDataJson)
    DicomMetadataStore.addInstance(insMetadata);
  });
  DicomMetadataStore.getStudy(studyInstanceUID).series.forEach(serie => {
    serie.instances.forEach(ins => {
      ins.url = `wadouri:https://${azData.account}.blob.core.windows.net/${azData.container}/${ins.StudyInstanceUID}/${ins.SeriesInstanceUID}/${ins.SOPInstanceUID}.dcm`;

    })
  })
  setCurrentStudy(DicomMetadataStore.getStudy(studyInstanceUID));
  window["isStillLoading"] = false; // to show loader with icon, add these line wherever it is required for viewport
}

async function fetchDicomP10Buffer(url) {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch DICOM P10 buffer from ${url}`);
  }
  return response.arrayBuffer();
}

async function _loadV3GzipLq(azData, loadHq) {
  console.log(azData.account);
  let azureUrl = `https://${azData.account}.blob.core.windows.net/${azData.container}/${azData.object}/meta/dicom_metadata.json.gz`;
  const data = await fetchGzippedJSON(azureUrl);
  const studyInstanceUID = data[0]['0020000D'].Value[0];

  // Fetch and add instances in parallel
  const fetchAndAddPromises = data.map(async (ins) => {
    const SeriesInstanceUID = ins['0020000E'].Value[0];
    const SOPInstanceUID = ins['00080018'].Value[0];
    const Modality = ins['00080060'].Value[0];

    if ((Modality.toLowerCase() === 'cr' || Modality.toLowerCase() === 'dx') && !loadHq) {
      const lqUrl = `https://telefiradiostorage.blob.core.windows.net/${azData.container}/${azData.object}/lq/${SeriesInstanceUID}/${SOPInstanceUID}.dcm`;
      try {
        const dicomP10Buffer = await fetchDicomP10Buffer(lqUrl);
        const dicomData = dcmjs.data.DicomMessage.readFile(dicomP10Buffer, { ignoreErrors: true });
        const dataset = dcmjs.data.DicomMetaDictionary.naturalizeDataset(dicomData.dict);
        dataset._meta = dcmjs.data.DicomMetaDictionary.namifyDataset(dicomData.meta);
        dataset.url = `wadouri:${lqUrl}`;
        DicomMetadataStore.addInstance(dataset);
      } catch (error) {
        DicomMetadataStore.addInstance(ins);
      }
    } else {
      DicomMetadataStore.addInstance(ins);
    }
  });

  await Promise.all(fetchAndAddPromises);
  console.log("Metadata added successfully");

  /////////check for instance count in series and the data/////
  // Loop through each element in the original data array
  let removeInstances = new Map();
  let addInstances = []
  data.forEach((dataElement, index) => {
    const sopInstanceUID = dataElement['00080018'].Value[0];
    let found = false;
    DicomMetadataStore.getStudy(studyInstanceUID).series.forEach(serie => {
      serie.instances.forEach(ins => {
        if (sopInstanceUID === ins.sopInstanceUID) {
          found = true;
          if (ins.Modality.toLowerCase() === 'cr' || ins.Modality.toLowerCase() === 'dx') {
            if (ins.PixelData || ins.PixelData.length == 0) {
              //we need to remove this ins & add the data element
              const seriesInstanceUID = ins.seriesInstanceUID;
              if (!removeInstances.has(seriesInstanceUID)) {
                removeInstances.set(seriesInstanceUID, []);
              }
              // console.log('karthik-no pixel data', sopInstanceUID);
              removeInstances.get(seriesInstanceUID).push(ins);
              addInstances.push(dataElement); // M
            }
          }
        }
      })
    })
    if (!found) {
      addInstances.push(dataElement);
    }
  });

  // Assuming removeInstances is a Map where the key is SeriesInstanceUID and the value is an array of instances to remove
  DicomMetadataStore.getStudy(studyInstanceUID).series.forEach(serie => {
    // Check if there are instances to remove for this series
    if (removeInstances.has(serie.SeriesInstanceUID)) {
      const instancesToRemove = removeInstances.get(serie.SeriesInstanceUID);

      // Iterate backwards through instancesToRemove to safely remove instances from serie.instances
      for (let i = instancesToRemove.length - 1; i >= 0; i--) {
        const insToRemove = instancesToRemove[i];
        // Find the index of the instance to remove in the current series
        console.log('karthik-no pixel data - remove from series', insToRemove.sopInstanceUID);
        const index = serie.instances.findIndex(ins => ins.sopInstanceUID === insToRemove.sopInstanceUID);
        if (index !== -1) {
          // Remove the instance using splice
          serie.instances.splice(index, 1);
        }
      }
    }
  });

  addInstances.forEach((ins) => {
    console.log('karthik- addInstance', ins['00080018'].Value[0]);
    DicomMetadataStore.addInstance(ins);
  })

  //////////update ins.url/////////////////////////////////////
  DicomMetadataStore.getStudy(studyInstanceUID).series.forEach(serie => {
    serie.instances.forEach(ins => {
      if (!ins.url) {
        ins.url = `wadouri:https://telefiradiostorage.blob.core.windows.net/${azData.container}/${azData.object}/${ins.SeriesInstanceUID}/${ins.SOPInstanceUID}.dcm`;
      }
    })
  })
  setCurrentStudy(DicomMetadataStore.getStudy(studyInstanceUID));
  window["isStillLoading"] = false; // to show loader with icon, add these line wherever it is required for viewport
}


async function loadStudyFromAzure(params, query, blobData) {
  //read params from window.location
  const blob = query.get("blob");
  let azData;
  if (blobData) {
    azData = JSON.parse(atob(blobData));
    window["azureData"] = azData;
    console.log(azData);
  }
  const studyUid = query.get("studyUid");
  if (!azData) {
    azData = {};
    azData["account"] = "telefiradiostorage";
    azData["container"] = "dicom-data";
    azData["object"] = studyUid;
  }
  if (!azData) {
    console.log("blob not found & studyUid not found");
  }

  let loadHq = query.get("hq");
  window["hq"] = loadHq
  loadHq = loadHq === 'true'; // Toggle the state

  // if(azData?.modality?.toLowerCase() === 'cr' || azData?.modality?.toLowerCase() === 'dx'){
  //   try {
  //     // console.log("loading v3 Gzip")
  //     await _loadV3GzipLq(azData, loadHq);
  //     // console.log("completed loading v3 Gzip metadata")
  //     return;
  //   } catch (error) {
  //     console.log("unable to load v3 gzip", error);
  //   }
  // }

  try {
    // console.log("loading v3 Gzip")
    await _loadV3Gzip(azData, loadHq);
    // console.log("completed loading v3 Gzip metadata")
    return;
  } catch (error) {
    console.log("unable to load v3 gzip", error);
  }
  try {
    console.log("loading v2 metadata")
    await _loadV2(azData);
    console.log("completed loading v2 metadata")
    return;
  } catch (error) {
    console.log("unable to load v2 series.json", error)
  }

  try {
    // console.log("loading v2 metadata")
    await _loadV2_without_ohif_in_path(azData);
    // console.log("completed _loadV2_without_ohif_in_path v2 metadata")
    return;
  } catch (error) {
    console.log("unable to load v2 series.json", error)
  }

  try {
    // console.log("loading v3 metadata")
    await _loadV3(azData);
    // console.log("completed loading v3 metadata")
    return;
  } catch (error) {
    console.log("unable to load v3 dicom_metadata.json", error)
  }
}

function setCurrentStudy(study) {
  window["currentStudy"] = study;
  window['capturedImages'] = [];
}

async function _loadV2_without_ohif_in_path(azData) {
  let azureUrl = `https://${azData.account}.blob.core.windows.net/${azData.container}/${azData.object}/meta/series.json`;

  const { studyInstanceUID, seriesUids } = await getSeriesUids(azureUrl);

  const promises = seriesUids.map(seriesUid => {
    const url = `https://${azData.account}.blob.core.windows.net/${azData.container}/${azData.object}/meta/${seriesUid}_metadata.json`;
    return getMetadataForSeriesUid(url).catch(e => {
      console.error(
        `Failed to fetch metadata for series UID ${seriesUid}. Error: ${e.message}`
      );
      return null;
    });
  });

  const allMetadata = await Promise.all(promises);

  allMetadata.forEach(seriesInstances => {
    if (seriesInstances) {
      // Check if not null due to catch returning null for failed fetches
      seriesInstances.forEach(ins => {
        DicomMetadataStore.addInstance(ins);
      });
    }
  });

  DicomMetadataStore.getStudy(studyInstanceUID).series.forEach(serie => {
    serie.instances.forEach(ins => {
      ins.url = `wadouri:https://${azData.account}.blob.core.windows.net/${azData.container}/${azData.object}/${ins.SeriesInstanceUID}/${ins.SOPInstanceUID}.dcm`;
    });
  });
  setCurrentStudy(DicomMetadataStore.getStudy(studyInstanceUID));
}

async function _loadV2(azData) {
  let azureUrl = `https://${azData.account}.blob.core.windows.net/${azData.container}/${azData.object}/ohif/meta/series.json`;

  const { studyInstanceUID, seriesUids } = await getSeriesUids(azureUrl);

  const promises = seriesUids.map(seriesUid => {
    const url = `https://${azData.account}.blob.core.windows.net/${azData.container}/${azData.object}/ohif/meta/${seriesUid}_metadata.json`;
    return getMetadataForSeriesUid(url).catch(e => {
      console.error(
        `Failed to fetch metadata for series UID ${seriesUid}. Error: ${e.message}`
      );
      return null;
    });
  });

  const allMetadata = await Promise.all(promises);

  allMetadata.forEach(seriesInstances => {
    if (seriesInstances) {
      // Check if not null due to catch returning null for failed fetches
      seriesInstances.forEach(ins => {
        DicomMetadataStore.addInstance(ins);
      });
    }
  });

  DicomMetadataStore.getStudy(studyInstanceUID).series.forEach(serie => {
    serie.instances.forEach(ins => {
      ins.url = `wadouri:https://${azData.account}.blob.core.windows.net/${azData.container}/${azData.object}${ins.StudyInstanceUID}/${ins.SeriesInstanceUID}/${ins.SOPInstanceUID}.dcm`;
    });
  });
  setCurrentStudy(DicomMetadataStore.getStudy(studyInstanceUID));
}

async function _loadV3UsingSeriesJson(studyUid) {
  const azData = {
    account: 'telefiradiostorage',
    container: 'dicom-data',
    object: studyUid,
  };

  let azureUrl = `https://${azData.account}.blob.core.windows.net/${azData.container}/${azData.object}/meta/series.json`;

  const { studyInstanceUID, seriesUids } = await getSeriesUids(azureUrl);

  const promises = seriesUids.map(seriesUid => {
    const url = `https://${azData.account}.blob.core.windows.net/${azData.container}/${azData.object}/meta/${seriesUid}_metadata.json`;
    return getMetadataForSeriesUid(url).catch(e => {
      console.error(
        `Failed to fetch metadata for series UID ${seriesUid}. Error: ${e.message}`
      );
      return null;
    });
  });
  const allMetadata = await Promise.all(promises);

  allMetadata.forEach(seriesInstances => {
    if (seriesInstances) {
      // Check if not null due to catch returning null for failed fetches
      seriesInstances.forEach(ins => {
        DicomMetadataStore.addInstance(ins);
      });
    }
  });

  DicomMetadataStore.getStudy(studyInstanceUID).series.forEach(serie => {
    serie.instances.forEach(ins => {
      // ins.url = `wadouri:https://telefiradiostorage.blob.core.windows.net/dicom-data/${ins.StudyInstanceUID}/${ins.SeriesInstanceUID}/${ins.SOPInstanceUID}.dcm`;
      ins.url = `wadouri:https://telefiradiostorage.blob.core.windows.net/${azData.container}/${azData.object}/${ins.SeriesInstanceUID}/${ins.SOPInstanceUID}.dcm`;

    });
  });
  setCurrentStudy(DicomMetadataStore.getStudy(studyInstanceUID));

}

export default loadStudyFromAzure;
