// EventLogger.ts

import moment from "moment";
import uuidv4 from "../../utils/uuidv4";


interface ISessionWithEvents{
    session_id: string,
    event_type: string,
    studyreporttask_id: string,
    radiologist_id: string,
    study_uid: string,
    events:Record<string, any>[]
    url: string,
    device: string,
    session_start_time: number  | any,
}

export default class EventLogger {
  private static instance: EventLogger;
  private session:ISessionWithEvents = {} as ISessionWithEvents;
  private interactionEvents: Record<string, any>[] = [];
  private isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  private constructor() {}

  public static getInstance(): EventLogger {
    if (!EventLogger.instance) {
      EventLogger.instance = new EventLogger();
    }
    // create  session for current instance
    return EventLogger.instance;
  }

  public logInteraction(interactionLog: Record<string, any>): void {
    //TODO: InteractionLogs
    // add interactionEvents on window
    // add capture logs
  /*
    session_id
    task_id
    radiologist_id
    study_uid
    events
      - commands -> tool_name, scroll, series_selection, measurement_added, measurement_started
      - measurement_added
      - action - tool_name, scroll, series_selection, measurement_added, measurement_started
      - series_uid
      - image_index
      - image_length
      - image_url
  */
    const url = window.location.href;
      const captureEvents = new URL(url).searchParams.get("capture_events");
      const domain = window.location.hostname;
      // console.log(captureEvents);
      // console.log(interactionLog);
      // console.log(window['sliceEventData']);
    if (window['sliceEventData'] && ((window['azureData'].radID !== "" && window['azureData'].radID !== undefined) || captureEvents === "true")) {
      if(this.session.session_id === undefined){
        this.session.session_id= uuidv4();
        // this.session.event_type ="viewer";
        this.session.studyreporttask_id= window['azureData'].taskID;
        this.session.radiologist_id=window['azureData'].radID;
        this.session.study_uid = window['sliceEventData']?.study_instance_id;
        this.session.events =[];
        this.session.url = domain;
        this.session.device = this.isMobile ? 'mobile' : 'desktop',
        this.session.session_start_time = moment(new Date()).format("DD-MM-yyyy HH:mm:ss Z");
      }
      // console.log(interactionLog);
      // var imageIndex = (typeof window['sliceEventData'].image_index !== 'undefined' && window['sliceEventData'].image_index !== null) ? window['sliceEventData'].image_index : 'NA';
      // (typeof window['sliceEventData'].images_length !== 'undefined' && window['sliceEventData'].images_length !== null) ? window['sliceEventData'].images_length : 'NA'

      const filteredData = {
        commands: interactionLog.commandsExecuted || 'NA',
        action: interactionLog.interactionType || 'NA',
        series_uid: interactionLog.series_id || window['sliceEventData'].series_id ||  'NA',
        image_index: interactionLog.image_index || (typeof window['sliceEventData'].image_index !== 'undefined' && window['sliceEventData'].image_index !== null) ? window['sliceEventData'].image_index : 'NA',
        image_length: interactionLog.images_length || (typeof window['sliceEventData'].images_length !== 'undefined' && window['sliceEventData'].images_length !== null) ? window['sliceEventData'].images_length : 'NA',
        image_url: interactionLog.image_url || window['sliceEventData'].image_url || 'NA',
        measurement_added: interactionLog.measurement_added  || "NA",
        measurement_started:  interactionLog.measurement_started || "NA",
        start_time:  moment(new Date()).format("DD-MM-yyyy HH:mm:ss Z")
      };
      if(this.session.study_uid.includes("init")){
        this.session.study_uid = window['sliceEventData']?.study_instance_id;
      }

      this.session.events.push({...filteredData})

      window["eventsData"] = this.session;

    }

  }

  public getInteractionLogs(): Record<string, any>[] {
    return this.interactionEvents;
  }
  public logAllInteractionsToConsole(): void {
    // console.log(this.session);
    // Set the start time when you begin logging
    const startTime = new Date().getTime();

    const intervalId = setInterval(() => {
      // console.log(this.session);

      // Example: Stop logging after 10 seconds
      const currentTime = new Date().getTime();
      if (currentTime - startTime >= 10000) {
        clearInterval(intervalId);
        console.log('Logging interactions stopped');
      }
    }, 10000);
  }


// API services
private STAGE_BASE_URL = 'https://stage.radsmart.in/api/';

 public async postAIDataEvents(data: any): Promise<void> {
  // if(window["prevEventsLength"] === window["eventsData"].events.length){
  //   return;
  // }
  try {
    const response = await fetch(`${this.STAGE_BASE_URL}v2/ai_analysis/radiologist_activity_session/create_or_update/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (response.ok) {
      // console.log(window["prevEventsLength"]);
      // console.log(window["eventsData"].events.length);
      window["prevEventsLength"] = window["eventsData"].events.length;
    } else {
      // window["eventsData"].events = [...data.session_dump.events, ...window["eventsData"].events];

      throw new Error('Failed to post AI data events');
    }
  } catch (error) {
    // window["eventsData"].events = {...data.session_dump.events, ...window["eventsData"].events};
    // window["eventsData"].events = [...data.session_dump.events, ...window["eventsData"].events];

    console.error('Error posting AI data events:', error);
    throw error; // Re-throw the error for the caller to handle if needed
  }

}

}



export function createInteractionLog(interactionType: string, itemId: string): Record<string, any> {
  const interactionId = uuidv4();
  return {
    id: interactionId,
    startTime: new Date(),
    // endTime: null,
    interactionType,
    itemId,
    commandsExecuted: [],
  };
}


export { EventLogger,  };
// export default EventLogger;
